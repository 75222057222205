import { lang } from "./config";
const langLiveRoom = {
  live_room_management: ["直播间管理", "Live Room Management"],
  anchor_information: ["主播信息", "Anchor Information"],
  live_room_info: ["直播间信息", "Live Room Information"],
  operate: ["操作", "Operate"],
  debug: ["直播调试", "Debug"],
  live_debug: ["直播间调试", "Live Debugging"],
  debut: ["开播", "Debut"],
  edit: ["编辑", "Edit"],
  delete: ["删除", "Delete"],
  no_living: ["暂无直播间", "There is currently no live streaming room available"],
  add_live_room: ["创建直播间", "Create Live Room"],
  live_way: ["主播通过app扫码进入直播进行推流", "The anchor enters the live streaming mini program through WeChat QR code scanning for streaming"],
  enter_computer: ["进入电脑端", "Enter The Computer"],
  missing_parameter: ["缺少必要参数，创建二维码失败", "Missing necessary parameters, creating QR code failed"],
  hint_message: ["提示消息", "Prompt Message"],
  del_message: ["删除后将无法恢复，确定要删除？", "It will not be restored after deletion. Are you sure you want to delete it?"],
  confirm: ["确定", "Confirm"],
  cancel: ["取消", "Cancel"],
  del_success: ["删除成功", "Successfully Deleted"],
  del_cancel: ["已取消删除", "Cancelled Deletion"],
  base_info: ["基本信息", "Basic Information"],
  live_room_config: ["直播间配置", "Live Room Configuration"],
  next_step: ["下一步", "Next Step"],
  last_step: ["上一步", "Last Step"],
  add: ["创建", "Set Up"],
  living_type: ["直播类型", "Live Streaming Type"],
  phone_living: ["手机直播", " Streaming"],
  phone_living_msg: ["手机直播（通过APP直播开播）", "Mobile live streaming (via APP live streaming)"],
  computer_living: ["电脑端直播", "Computer Based Live Streaming"],
  live_room_title: ["直播间标题", "Live Room Title"],
  living_cover: ["直播封面", "Live Cover"],
  title_msg: ["请输入直播间标题（4-17）", "Please enter the title of the live broadcast room (4-17)"],
  live_room_abstract: ["直播间简介", "Direct Broadcasting Room"],
  abstract_msg: ["请输入直播间简介（8-500）", "Please enter a brief description (8-500)"],
  anchor_nickname: ["主播昵称", "Anchor Nickname"],
  nickname_msg: ["请输入主播昵称（5-15）", "Please enter the host nickname (5-15)"],
  profile_photo_msg: ["建议尺寸比例1:1", "Recommended size ratio 1:1"],
  live_cover_msg: ["建议尺寸：1080*1920px，图片大小不得超过3MB", "Recommended size: 1080*1920px, image size should not exceed 3MB"],
  preview_effect: ["预览效果", "Preview Effect"],
  title_hint: ["请输入直播间标题", "Please enter the title of the live broadcast room"],
  title_verify: ["长度在 4 到 17 个字符", "Between 4 and 17 characters in length"],
  nickname_hint: ["请输入主播昵称", "Please enter the host nickname"],
  nickname_verify: ["长度在 5 到 15 个字符", "Between 5 and 15 characters in length"],
  abstract_hint: ["请输入直播间简介", "Please enter the introduction of the live broadcast room"],
  abstract_verify: ["长度在 8 到 500 个字符", "Between 8 and 500 characters in length"],
  type_hint: ["请选择直播类型", "Please select a live streaming type"],
  img_upload_err: ["图片上传失败，请稍后再试~", "Image upload failed. Please try again later~"],
  img_size_err: ["上传图片大小不能超过 3MB!", "Upload picture size cannot exceed 3MB!"],
  profile_photo: ["头像", "Profile Photo"],
  zero_like: ["0本场点赞", "0 Like This Game"],
  attention: ["关注", "attention"],
  Living_wall: ["直播背景墙", "Live background wall"],
  add_goods: ["添加商品", "Add Goods"],
  goods_news: ["商品信息", "Goods News"],
  no_data: ["暂无数据", "No Data"],
  upload_patch: ["上传贴片", "Upload Patch"],
  re_upload: ["重新上传", "re-upload"],
  wall_photo_msg: ["图片尺寸限制为1:1，大小不可超过3MB", "The image size is limited to 1:1 and cannot exceed 3MB in size"],
  preview: ["预览", "preview"],
  no_upload_patch: ["暂无贴片", "No Upload Patch"],
  live_footage: ["直播间素材", "Live Footage"],
  download_material: ["下载素材", "Download Material"],
  wall_photo_verify: ["图片尺寸限制为1080px x 1920px，大小不可超过3MB", "The image size is limited to 1080px x 1920px, and the size cannot exceed 3MB"],
  good_img_err: ['图片尺寸限制为1:1，大小不可超过3MB', "The image size is limited to 1:1 and cannot exceed 3MB in size"],
  please_select_product: ["请先选择商品", "Please Select Product"],
  please_upload_patch: ["请上传贴片", "Please Upload Patch"],
  select_product_err: ["您还未添加商品，请回到选品处添加商品！", "You have not added the product, please go back to the selection to add the product!"],
  bg_wall_msg: ["请添加直播背景墙！", "Please add live background wall!"],
  add_goods_err: ["请添加商品！", "Please add products!"],
  please_select: ["请添加商品！", "Please Select"],
  start_test: ["开始测试", "Start Test"],
  end_test: ["结束测试", "End Test"],
  push_port: ["app推流入口", "Small Program Push Inflow Port"],
};
export default lang(langLiveRoom);
