<template>
    <div class="live-content">
        <el-scrollbar>
            <el-breadcrumb class="live-breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>{{ lang.live_room_management }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ lang.add_live_room }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="live-title">{{ lang.add_live_room }}</div>
            <div class="steps-wrapper">
                <div class="step-item" :class="{current: [1,2].includes(stepActive)}">
                    <div class="step-line"></div>
                    <div class="step-text">01</div>
                    <div class="step-title">{{ lang.base_info}}</div>
                </div>
                <div class="step-item" :class="{current: [2].includes(stepActive)}">
                    <div class="step-line"></div>
                    <div class="step-text">02</div>
                    <div class="step-title">{{ lang.live_room_config }}</div>
                </div>
            </div>
            <div class="form-content">
                <liveBaseInfo ref="liveBaseInfo" v-if="stepActive === 1" @stepActiveAdd="stepActiveAdd"
                              :stepOneData="stepOneData"></liveBaseInfo>
                <liveConfig ref="liveConfig" v-if="stepActive === 2" :stepOneData="stepOneData"
                            :stepTwoData="stepTwoData" :fileUrl="fileUrl"
                            @stepActiveAdd="stepActiveAdd"></liveConfig>
            </div>
            <div class="footer-content">
                <el-button class="fade-btn" v-if="stepActive === 1" @click="toBack">{{ lang.cancel }}</el-button>
                <el-button type="primary" v-if="stepActive === 1" @click="nextStep">{{ lang.next_step }}</el-button>
                <el-button type="primary" v-if="stepActive === 2" @click="lastStep">{{ lang.last_step }}</el-button>
                <el-button type="primary" v-if="stepActive === 2" @click="createLive">{{ lang.add }}</el-button>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import liveBaseInfo from "../../../components/student/liveManage/liveBaseInfo";
    import liveConfig from "../../../components/student/liveManage/liveConfig";
    import {liveRoom_list, liveRoom_save, liveRoom_getScriptFile} from '@/utils/apis'
    import lang from "@/lang/studioManagement"

    export default {
        name: "createLiveRoom",
        components: {
            liveConfig,
            liveBaseInfo
        },
        data() {
            return {
                stepActive: 1,
                stepOneData: null,
                stepTwoData: null,
                fileUrl: '',
                lang: lang
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.getInfo()
            }
            this.getFile()
        },
        methods: {
            getInfo() {
                let param = {
                    id: this.$route.query.id
                }
                liveRoom_list(param).then(res => {
                    this.stepOneData = {
                        title: res.data.title,
                        live_type: JSON.stringify(res.data.live_type),
                        nickname: res.data.nickname,
                        description: res.data.description,
                        avatar: res.data.avatar,
                        cover: res.data.cover
                    }
                    this.stepTwoData = {
                        live_background: res.data.live_background,
                        goods_info_patch: res.data.goods_info_patch
                    }
                })
            },
            getFile() {
                liveRoom_getScriptFile().then(res => {
                    this.fileUrl = res.data.src
                })
            },
            toBack() {
                this.$router.push('/liveRoomManage/index')
            },
            nextStep() {
                this.$refs.liveBaseInfo.confirmForm()
            },
            lastStep() {
                this.stepActive--
            },
            createLive() {
                this.$refs.liveConfig.confirmForm()
            },
            stepActiveAdd(val) {
                if (this.stepActive === 1) {
                    this.stepOneData = val;
                    this.stepActive++
                } else {
                    this.stepTwoData = val
                    this.mainConfirm()
                }
            },
            mainConfirm() {
                let params = Object.assign(this.stepOneData, this.stepTwoData)
                if (this.$route.query.id) {
                    params.id = this.$route.query.id
                }
                liveRoom_save(params).then(res => {
                    this.$message.success(res.msg)
                    this.toBack()
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .live-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .el-scrollbar {
            margin: 20px;
            height: calc(100% - 40px);
        }

        ::v-deep .el-scrollbar__wrap {
            height: 100%;
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .live-title {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
        }
    }

    .live-breadcrumb {
        ::v-deep.el-breadcrumb__item {

            &:last-child .el-breadcrumb__inner {
                color: #ABABBB;
            }

            .el-breadcrumb__inner {
                color: #5C5C6F;
            }

            .el-breadcrumb__separator {
                color: #5C5C6F;
            }
        }
    }

    .steps-wrapper {
        margin: 30px 0 0;

        ::v-deep .step-item {

            .step-title {
                color: #fff;
                opacity: 0.2;
            }

            .step-line {
                left: -140px
            }

            .step-text {
                background: #262963;
                opacity: 0.5;
            }

            &.current {
                .step-text {
                    background: #2821FC;
                    opacity: 1;
                }

                .step-title {
                    opacity: 1;
                }
            }
        }
    }

    .form-content {
        /*flex: 1;*/
        /*height: 1%;*/
        width: 100%;
        margin-top: 20px;
        box-sizing: content-box;
    }

    .footer-content {
        text-align: center;
        margin: 20px 0;

        .fade-btn {
            background: #0C0E3F;
            border: 1px solid #262963;
            color: #fff;
        }
    }
</style>